.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  width: 100vw;
  height: 100vh;
}

li {
  list-style-type: none;
}

.BigStripe {
  position: fixed;
  right: 580px;
  top: -450px;
  background: #f44646;
  width: 150px;
  height: 2000px;
  transform: rotate(45deg);
}

.LilStripe {
  position: fixed;
  right: 450px;
  top: -450px;
  background: #f44646;
  width: 50px;
  height: 2000px;
  transform: rotate(45deg);
}


@media screen and (max-width: 400px) {
  .App {
    width: 100%;
    height: 100%;
  }
}