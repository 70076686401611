.Resume {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.titlediv h1 {
  font-size: 3rem;
  margin: 0;
  margin-bottom: 15px;
}

.Resume a {
  color: #222;
  font-size: .75rem;
  font-weight: bold;
  text-decoration: none;
  padding: 5px;
}


.ProjectsContent {
  width: 85%;
}

.titlediv sub {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 20px;
}

.contact p {
  display: inline-block;
  margin-bottom: 15px;

}

.SkillsContent {
  width: 85%;
  margin-top: 25px;
}

@media screen and (max-width: 400px) {
  .Resume {
    width: 100%;
  }
  .ProjectsContent {
    width: 100%;
  }
}