.MainWrapper {
  width: 100%;
}

@media screen and (max-width: 400px) {
  .MainWrapper {
    width: 100%;
    margin: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}