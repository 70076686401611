.Nav {
  width: 60%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  margin: auto;
  margin-bottom: 30px;
}

.Nav a {
  color: #555;
  text-decoration: none;
}

.Title {
  padding-top: 0;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.Title h1 {
  font-size: 2rem;
  font-weight: bolder;
  margin: 0;
}

.Title p {
  margin: 0;
  font-size: 0.5rem;
}

.NavList {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  font-size: 1.15rem;
  color: #555;
}

.NavItem {
  margin-left: 65px;
  cursor: pointer;
  transition: all ease-in .2s;
}

.NavItem a:hover {
  color: #111;
  font-weight: 300;
}

.NavItem.active {
  color: #f44646;
}

@media screen and (max-width: 400px) {
  .Nav {
    width: 100%;
    flex-flow: column;
    padding: 0;
    justify-content: center;
    margin-bottom: 20px;
  }
  .NavList {
    justify-content: space-between;
  }
  .NavItem {
    margin-left: 0;
    padding: 0 15px;
  }

}