.About {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: auto;
  line-height: 1.8rem;
}


.About p, .About ul {
  text-align: left;
}

.About-Special {
  font-size: 3rem;
  font-weight: bold;
  display: block;
  margin-bottom: 50px;
  text-align: left;
}


.Headshot-container {
  display: inline-block;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 5px solid rgba(34, 34, 34, 0.788);
  float: left;
  margin-right: 25px;
}

@media screen and (max-width: 400px) {
  .About-Special {
    line-height: 4rem;
    font-size: 2.5rem;
    text-align: center;
  }
  .Headshot-container {
    margin: 0;
    margin: auto;
    margin-bottom: 25px;
  }

}

